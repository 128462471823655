import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class CommonService {
  public baseUrl = environment.apiBaseUrl + "/comvvmons";

  constructor(private http: HttpClient) {}

  saveCommon(data) {
    return this.http.post(this.baseUrl + "/saveee", data, {
      responseType: "arraybuffer"
    });
  }

  getCommomRepost(data) {
    return this.http.put(this.baseUrl + "/getByDCType", data);
  }
  getPrint(data) {
    return this.http.post(this.baseUrl + "/getReprint", data, {
      responseType: "arraybuffer"
    });
  }
  getAllreturnDetails(data) {
    return this.http.put(this.baseUrl + "/getallReturntDetails", data);
  }
  conformGateMaterial(data) {
    return this.http.put(this.baseUrl + "/conformGateMaterial", data);
  }

  updateParts(data) {
    return this.http.put(this.baseUrl + "/updateParts", data);
  }
  deteteYardPartDetails(id) {
    const params = new HttpParams().set("id", id);
    return this.http.delete(this.baseUrl + "/delete", { params });
  }
  NotWorkdoneMaterial(data) {
    return this.http.put(this.baseUrl + "/NotWorkdoneMaterial", data);
  }
}
