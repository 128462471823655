import { DesignPartDetails } from "./design-part-details";

export class Design {
  _id: string;
  pumpModelId: string;
  pumpModel: string;
  packingType: string;
  packingTypeId: string;
  partNameId: string;
  lubricationType: string;
  lubricationTypeId: string;
  accessoriesType: string;
  drive: string;
  moc: number;
  quantity: number;
  status: string;
  createdBy: string;
  modifiedBy: string;
  designPartsDetails: DesignPartDetails[];
  partName: string;
  subDesignId: string;
  internalPartCode: string;
}
